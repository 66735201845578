import { FC } from 'react'
import { useEffect } from 'react'
import { useThemeContext } from '../../store/ThemeProvider';

const Tos: FC = () => {
    const [{ imageTheme }, { setImageTheme }] = useThemeContext()

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
          });
    },[])
    return (
        <>
            <div className='relative'>
                <div className='absolute xl:block lg:block hidden top-[424px] z-0'>
                    <img src={'/assets/content/privacy/star-left-'+ imageTheme + '.svg'} alt='star'></img>
                </div>
                <div className='absolute xl:block lg:block hidden top-[0px] right-[0px] z-0'>
                    <img src={'/assets/content/privacy/star-right-' + imageTheme + '.svg'} alt='star'></img>
                </div>
                <div className='mt-[80px] xl:mx-[155px] lg:mx-[155px] mx-[20px] z-10'>
                    <h1 className='text-[40px] font-bold dark:text-white'>Terms of Service</h1>
                    <div className='xl:flex lg:flex mt-[30px] gap-[20px]'>
                        <div className='xl:w-6/12 lg:w-6/12'>
                            <div>
                                <h1 className='text-[30px] font-bold dark:text-white'>Acceptance of Terms</h1>
                                <h1 className='mt-[16px] dark:text-white'>By using BetterLegalContent.Ai, you agree to be bound by these Terms of Service.</h1>
                            </div>
                            <div className='mt-[34px]'>
                                <h1 className='text-[30px] font-bold dark:text-white'>User Responsibilities</h1>
                                <h1 className='mt-[16px] dark:text-white'>As a user, you agree not to use our website for any unlawful purposes or in a way that is prohibited by these terms.</h1>
                            </div>
                            <div className='mt-[34px]'>
                                <h1 className='text-[30px] font-bold dark:text-white'>Account Terms</h1>
                                <h1 className='mt-[16px] dark:text-white'>If you create an account on our site, you are responsible for maintaining the confidentiality of your account and password.</h1>
                            </div>
                            <div className='mt-[34px]'>
                                <h1 className='text-[30px] font-bold dark:text-white'>Intellectual Property Rights</h1>
                                <h1 className='mt-[16px] dark:text-white'>The content of our website, including its text, graphics, and logos, are the property of BetterLegalContent.Ai and are protected by copyright and other laws.</h1>
                            </div>
                        </div>
                        <div className='xl:w-6/12 lg:w-6/12 xl:mt-[0] lg:mt-[0] mt-[34px]'>
                            <div>
                                <h1 className='text-[30px] font-bold dark:text-white'>Disclaimer of Warranties/Limitation of Liability</h1>
                                <h1 className='mt-[16px] dark:text-white'>Our website and its content are provided "as is". We do not warrant the completeness, reliability, or accuracy of this information.</h1>
                            </div>
                            <div className='mt-[34px]'>
                                <h1 className='text-[30px] font-bold dark:text-white'>Governing Law</h1>
                                <h1 className='mt-[16px] dark:text-white'>These terms and conditions are governed by and construed in accordance with the laws of Texas and you irrevocably submit to the exclusive jurisdiction of the courts in that State or location.</h1>
                            </div>
                            <div className='mt-[34px]'>
                                <h1 className='text-[30px] font-bold dark:text-white'>Changes to the Terms</h1>
                                <h1 className='mt-[16px] dark:text-white'>We reserve the right to modify or replace these Terms at any time. We will make an effort to provide at least 30 days' notice prior to any new terms taking effect.</h1>
                            </div>
                            <div className='mt-[34px]'>
                                <h1 className='text-[30px] font-bold dark:text-white'>Contact Us</h1>
                                <h1 className='mt-[16px] dark:text-white'>If you have any questions about this Privacy Policy, please contact us at <a href="mailto:info@betterlegalcontent.ai."><span className="text-teal hover:opacity-100 delay-10 duration-10 hover:text-[#218B8F] active:text-navy-blue">info@betterlegalcontent.ai.</span></a></h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Tos