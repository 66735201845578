import { FC } from "react";
import EmailIcon from '@mui/icons-material/Email';
import { useThemeContext } from '../../store/ThemeProvider';

const Thanks: FC = () => {
    const [{ imageTheme }, { setImageTheme }] = useThemeContext()

    return (
        <>
            <div className="relative overflow-hidden">
                <div className="flex justify-center z-50">
                    <div className="xl:w-[720px] lg:w-[690px] xl:mt-[80px] lg:mt-[80px] mt-[40px]">
                        <div>
                            <h1 className="text-center text-[40px] font-bold dark:text-white">Thank You!</h1>
                            <h1 className="text-[18px] xl:w-[520px] lg:w-[520px] mx-[auto] text-center dark:text-white">We're delighted that you've signed up for early access to<br /> <span className="font-bold">BetterLegalContent.Ai!</span><br />In the coming days, we'll send you an email with more information about our platform, a code for a free trial, and what to expect as we move closer to our launch date.</h1>
                        </div>
                        <div className="xl:mt-[140px] lg:mt-[140px] mt-[80px]">
                            <h1 className="text-center text-[40px] font-bold dark:text-white">Share with Others</h1>
                            <h1 className=" mx-auto text-center dark:text-white">Know someone else who might benefit from<br />BetterLegalContent.Ai?<br />Feel free to share our sign-up page with them:</h1>
                            <div className="flex justify-center mt-[20px]">
                                <div className="p-[14px] bg-[#197477] rounded-l-[8px]">
                                    <EmailIcon className="text-navy-blue" />
                                </div>
                                <a href="mailto:">
                                    <div className="py-[14px] px-[28px] bg-[#218B8F] opacity-100 hover:opacity-80 active:opacity-100 delay-10 duration-10 hover:bg-[#218B8F] active:bg-navy-blue rounded-r-[8px]">
                                        <h1 className="text-[20px] font-bold text-white">Share via Email</h1>
                                    </div> 
                                </a>
                            </div>
                        </div>
                        <div className="xl:mt-[140px] lg:mt-[140px] mt-[80px]">
                            <h1 className="xl:text-[40px] lg:text-[40px] text-[30px] font-bold text-center dark:text-white">Questions or Need More Information?</h1>
                            <h1 className="text-center dark:text-white">If you have any questions, don't hesitate to reach out to us at<br /><a href="mailto:info@betterlegalcontent.ai"><span className="text-teal hover:opacity-100 delay-10 duration-10 hover:text-[#218B8F] active:text-navy-blue">info@betterlegalcontent.ai.</span></a></h1>
                        </div>
                    </div>
                </div>
                <img src={"/assets/content/thanks/star-left-" + imageTheme + ".svg"} className="absolute top-[200px] xl:block lg:block hidden" alt="star"></img>
                <img src={"/assets/content/thanks/star-right-" + imageTheme + ".svg"} className="absolute top-[200px] right-0 xl:block lg:block hidden" alt="star"></img>
            </div>
        </>
    )
}

export default Thanks