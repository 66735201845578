import { FC } from 'react'
import { useEffect } from 'react'
import { useThemeContext } from '../../store/ThemeProvider';

const Privacy: FC = () => {

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
          });
    },[])

    const [{ imageTheme }, { setImageTheme }] = useThemeContext()
    return (
        <>
            <div className='relative'>
                <div className='absolute xl:block lg:block hidden top-[424px] z-0'>
                    <img src={'/assets/content/privacy/star-left-'+ imageTheme + '.svg'} alt='star'></img>
                </div>
                <div className='absolute xl:block lg:block hidden top-[0px] right-[0px] z-0'>
                    <img src={'/assets/content/privacy/star-right-' + imageTheme + '.svg'} alt='star'></img>
                </div>
                <div className='mt-[80px] xl:mx-[155px] lg:mx-[155px] mx-[20px] z-10'>
                    <h1 className='text-[40px] font-bold dark:text-white'>Privacy Policy</h1>
                    <div className='xl:flex lg:flex mt-[30px] gap-[20px]'>
                        <div className='xl:w-6/12 lg:w-6/12'>
                            <div>
                                <h1 className='text-[30px] font-bold dark:text-white'>Introduction</h1>
                                <h1 className='mt-[16px] dark:text-white'>Welcome to BetterLegalContent.Ai. This privacy policy outlines our practices regarding the collection, use, and disclosure of your information when you use our website.</h1>
                            </div>
                            <div className='mt-[34px]'>
                                <h1 className='text-[30px] font-bold dark:text-white'>Information Collection</h1>
                                <h1 className='mt-[16px] dark:text-white'>We may collect personal identification information (such as names, email addresses), non-personal identification information (such as browser type, device type), and website activity data (such as pages viewed).</h1>
                            </div>
                            <div className='mt-[34px]'>
                                <h1 className='text-[30px] font-bold dark:text-white'>How We Collect Information</h1>
                                <h1 className='mt-[16px] dark:text-white'>We collect information through various methods, including direct user input (such as form submissions) and automated technology (like cookies).</h1>
                            </div>
                            <div className='mt-[34px]'>
                                <h1 className='text-[30px] font-bold dark:text-white'>How We Use Information</h1>
                                <h1 className='mt-[16px] dark:text-white'>We use the collected information to improve our website, provide our services, respond to user inquiries, and send periodic emails (such as newsletters or updates).</h1>
                            </div>
                            <div className='mt-[34px]'>
                                <h1 className='text-[30px] font-bold dark:text-white'>Information Sharing</h1>
                                <h1 className='mt-[16px] dark:text-white'>We do not sell, trade, or rent your personal identification information to others. We may share generic aggregated demographic information not linked to any personal identification information with our business partners and trusted affiliates for the purposes outlined above.</h1>
                            </div>
                            <div className='mt-[34px]'>
                                <h1 className='text-[30px] font-bold dark:text-white'>Data Security</h1>
                                <h1 className='mt-[16px] dark:text-white'>We adopt appropriate data collection, storage, and processing practices to protect against unauthorized access, alteration, disclosure, or destruction of your personal information.</h1>
                            </div>
                        </div>
                        <div className='xl:w-6/12 lg:w-6/12 xl:mt-[0] lg:mt-[0] mt-[34px]'>
                            <div>
                                <h1 className='text-[30px] font-bold dark:text-white'>User Rights</h1>
                                <h1 className='mt-[16px] dark:text-white'>As a user, you may request to see the information we hold about you, and you may request that we delete your personal information from our system.</h1>
                            </div>
                            <div className='mt-[34px]'>
                                <h1 className='text-[30px] font-bold dark:text-white'>Cookies</h1>
                                <h1 className='mt-[16px] dark:text-white'>Our website may use "cookies" to enhance the user experience. If you wish to disable cookies, you may do so through your individual browser options.</h1>
                            </div>
                            <div className='mt-[34px]'>
                                <h1 className='text-[30px] font-bold dark:text-white'>Third-Party Websites</h1>
                                <h1 className='mt-[16px] dark:text-white'>Our site may contain links to other websites. Please be aware that we are not responsible for the content or privacy practices of such other sites.</h1>
                            </div>
                            <div className='mt-[34px]'>
                                <h1 className='text-[30px] font-bold dark:text-white'>Changes to This Privacy Policy</h1>
                                <h1 className='mt-[16px] dark:text-white'>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</h1>
                            </div>
                            <div className='mt-[34px]'>
                                <h1 className='text-[30px] font-bold dark:text-white'>Contact Us</h1>
                                <h1 className='mt-[16px] dark:text-white'>If you have any questions about this Privacy Policy, please contact us at <a href="mailto:info@betterlegalcontent.ai."><span className="text-teal hover:opacity-100 delay-10 duration-10 hover:text-[#218B8F] active:text-navy-blue">info@betterlegalcontent.ai.</span></a></h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Privacy