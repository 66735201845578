import { FC, useEffect } from 'react'
import { Link } from 'react-router-dom';

const Footer: FC = ({}) => {

    useEffect(() => {
        console.log("theme=",localStorage.getItem('theme'));
    },[])

    return (
        <>
            <div className='border-t-[2px] xl:flex lg:flex md:flex block justify-between xl:px-[155px] lg:px-[155px] py-[30px] px-[20px] bg-transparent xl:mt-[150px] lg:mt-[150px] mt-[80px]'>    
                <h1 className='xl:text-[16px] lg:text-[16px] md:text-[16px] text-[14px] text-center font-semibold dark:text-white'>©2023&nbsp;BetterLegalContent.Ai.&nbsp;All&nbsp;Rights&nbsp;Reserved.</h1>                
                <div className='xl:flex lg:flex md:flex gap-[49px]'>
                    <Link to='/privacy_policy'>
                        <h1 className='xl:text-[16px] lg:text-[16px] md:text-[16px] text-[14px] text-center font-semibold dark:text-white xl:mt-[0px] lg:mt-[0px] md:mt-[0px]  mt-[17px]'>Privacy&nbsp;Policy</h1>
                    </Link>
                    <Link to='/terms_of_service'>
                        <h1 className='xl:text-[16px] lg:text-[16px] md:text-[16px] text-[14px] text-center font-semibold dark:text-white xl:mt-[0px] lg:mt-[0px] md:mt-[0px]  mt-[17px]'>Terms&nbsp;of&nbsp;Service</h1>
                    </Link>
                </div>
            </div>
        </>
    )
}

export default Footer