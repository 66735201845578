import { FC } from 'react'
import Faq from './Faq'
import Introduce from './Introduce'
import Writing from './Writing'
import Unlimited from './Unlimited'

const Landing: FC = ({ }) => {
    return (
        <>
            <div>
                <Introduce />
                <Writing />
                <Faq />
                <Unlimited />
            </div>
        </>
    )
}

export default Landing