/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useReducer, useContext, createContext, useMemo } from "react";

type State = {
  [x: string]: string | boolean;
};

type Actions = {
  setImageTheme: (value: string) => void;
};

const INIT_STATE = {
  imageTheme: "light",
};

const ThemeContext = createContext<[State, Actions]>([
  INIT_STATE,
  {
    setImageTheme: (_value: string) => {},
  },
]);
ThemeContext.displayName = "ThemeContext";

function reducer(state: object, { type, payload }: { type: string; payload: string | boolean }) {
  return {
    ...state,
    [type]: payload,
  };
}

type ProviderProps = {
  children: React.ReactNode;
};

const ThemeContextProvider = (props: ProviderProps) => {
  const [state, dispatch] = useReducer(reducer, INIT_STATE);

  const setImageTheme = (value: string) => dispatch({ type: "imageTheme", payload: value });

  return (
    <ThemeContext.Provider
      value={useMemo(
        () => [
          state,
          {
            setImageTheme,
          },
        ],
        [state],
      )}
    >
      {props.children}
    </ThemeContext.Provider>
  );
};

const useThemeContext = () => {
  const context = useContext(ThemeContext);
  if (context === undefined) {
    throw new Error("useThemeContext must be used within a ThemeProvider");
  }
  return context;
};

export { ThemeContextProvider, useThemeContext };
