import { FC } from 'react';
import AppRouter from './router/AppRouter'
import './App.scss'
import { ThemeContextProvider } from './store/ThemeProvider'

const App: FC = () => {


  return (
    <>
      <div className='bg-[#F5F5F5] dark:bg-black min-h-screen relative'>
        <ThemeContextProvider>
          <AppRouter />
        </ThemeContextProvider>
      </div>
    </>
  )
}

export default App;