import { FC } from 'react';
import { Routes, Route } from 'react-router-dom';
import { PUBLIC_ABS_ROUTE_PATHS } from '../core/constants';
import { Landing } from '../pages/landing';
import { Thanks } from '../pages/thank_you';
import { Tos } from '../pages/TOS'
import { Privacy } from '../pages/Privacy';

const PublicRoutes: FC =() => {
    return (
        <>
        <Routes>
            <Route path={PUBLIC_ABS_ROUTE_PATHS.landing} element={<Landing />}></Route>
            <Route path={PUBLIC_ABS_ROUTE_PATHS.thanks} element={<Thanks />}></Route>
            <Route path={PUBLIC_ABS_ROUTE_PATHS.privacy} element={<Privacy />}></Route>
            <Route path={PUBLIC_ABS_ROUTE_PATHS.tos} element={<Tos />}></Route>
        </Routes>
        </>
    )
}

export default PublicRoutes