import { FC } from "react";
import { useState } from "react";
import {
    ChevronUpIcon, ChevronDownIcon
} from "@heroicons/react/24/outline";

const Faq: FC = () => {

    const [drop1, setDrop1] = useState(false)
    const [drop2, setDrop2] = useState(false)
    const [drop3, setDrop3] = useState(false)
    const [drop4, setDrop4] = useState(false)
    const [drop5, setDrop5] = useState(false)
    const [drop6, setDrop6] = useState(false)
    const [drop7, setDrop7] = useState(false)
    const [drop8, setDrop8] = useState(false)

    return (
        <>
            <div className="mt-[140px] xl:px-[155px] lg:px-[155px] md:px-[155px] px-[20px]">
                <h1 className="xl:text-[40px] lg:text-[40px] md:text-[40px] text-[30px] dark:text-white font-bold">Frequently Asked Questions</h1>
                <div className="xl:flex lg:flex gap-[20px] mt-[24px]">
                    <div className="xl:w-6/12 lg:w-6/12  w-full">
                        <div className="border-t-[2px] flex">
                            <div className="xl:w-10/12 lg:w-10/12 md:w-10/12 w-11/12">
                                <h1 className={"dark:text-white xl:text-[20px] lg:text-[20px] md:text-[20px] text-[18px] font-bold mt-[24px] "}>What is BetterLegalContent.Ai?</h1>
                                {/* {drop1 && <h1 className={"dark:text-white xl:text-[18px] lg:text-[18px] md:text-[18px] text-[14px] mt-[14px] max-h-0 overflow-hidden transition-all duration-500 ease-in-out max-h-full" }> BetterLegalContent.Ai is a groundbreaking platform designed to simplify and streamline legal content creation. By harnessing the power of OpenAI, we offer unlimited content creation, curated webpage and blog post topics, and pre-engineered prompts. Our platform also provides upgraded services, including professional editing and licensed attorney review, ensuring high-quality, accurate legal content.</h1>} */}
                                <h1 className={"dark:text-white xl:text-[18px] lg:text-[18px] md:text-[18px] text-[14px] max-h-0 overflow-hidden transition-all duration-500 ease-in-out " + (drop1 ? "max-h-full mt-[14px]" : " ")}>
                                    BetterLegalContent.Ai is a groundbreaking platform designed to simplify and streamline legal content creation. By harnessing the power of OpenAI, we offer unlimited content creation, curated webpage and blog post topics, and pre-engineered prompts. Our platform also provides upgraded services, including professional editing and licensed attorney review, ensuring high-quality, accurate legal content.
                                </h1>

                            </div>
                            <div className="xl:w-2/12 lg:w-2/12 md:w-2/12 w-1/12 relative">
                                {
                                    drop1 ?
                                        <div className="cursor-pointer" onClick={() => { setTimeout(() => {
                                            console.log("false"); setDrop1(false)
                                        }, 300);  }} >
                                            <ChevronUpIcon className="h-6 w-6 text-white bg-[#218B8F] opacity-100 hover:opacity-80 active:opacity-100 delay-30 duration-300 hover:bg-[#218B8F] active:bg-navy-blue rounded-[8px] absolute top-[24px] right-0"
                                            /> </div> :
                                        <div className="cursor-pointer" onClick={() => { console.log("true"); setDrop1(true) }}>
                                            <ChevronDownIcon className="h-6 w-6 text-white bg-[#218B8F] opacity-100 hover:opacity-80 active:opacity-100 delay-30 duration-300 hover:bg-[#218B8F] active:bg-navy-blue rounded-[8px] absolute top-[24px] right-0"
                                            />
                                        </div>
                                }
                            </div>
                        </div>

                        <div className="border-t-[2px] flex mt-[24px]">
                            <div className="xl:w-10/12 lg:w-10/12 md:w-10/12 w-11/12">
                                <h1 className="dark:text-white xl:text-[20px] lg:text-[20px] md:text-[20px] text-[18px] font-bold mt-[24px]">How is BetterLegalContent.Ai different from ChatGPT?</h1>
                                <h1 className={"dark:text-white xl:text-[18px] lg:text-[18px] md:text-[18px] text-[14px] max-h-0 overflow-hidden transition-all duration-500 ease-in-out " + (drop3 ? "max-h-full mt-[14px]" : " ")}> BetterLegalContent.Ai is specifically designed for legal content creation, offering a range of features and services tailored to the needs of lawyers, law firms, and legal marketing agencies. While ChatGPT is a versatile AI language model that can be used for various applications BetterLegalContent.Ai focuses on providing the following advantages for legal professionals:
                                    <ul className="list-disc ml-6">
                                        <li className="dakr:text-white"><span className="font-semibold">Curated Topics and Suggestions:</span> We offer curated webpage and blog post topics, as well as data-driven suggestions, to help you efficiently create relevant and engaging legal content.</li>
                                        <li className="dakr:text-white"><span className="font-semibold">Pre-Engineered Prompts:</span> Our platform uses pre-engineered prompts designed to optimize content quality and ensure that the AI-generated content meets the specific requirements of the legal industry.</li>
                                        <li className="dakr:text-white"><span className="font-semibold">Professional Editing Services:</span> BetterLegalContent.Ai provides optional professional editing services to polish your AI-generated content, ensuring it meets high standards of clarity, coherence, and readability.</li>
                                        <li className="dakr:text-white"><span className="font-semibold">Licensed Attorney Review:</span> To guarantee the highest level of accuracy and legal compliance, we offer certified legal review services conducted by experienced attorneys.</li>
                                        <li className="dakr:text-white"><span className="font-semibold">Tailored Pricing and Plans:</span> Our pricing is competitive with ChatGPT, while offering a range of specialized services for the legal industry, including unlimited content creation and access to upgraded services. By focusing on the unique needs of legal professionals BetterLegalContent.Ai delivers a comprehensive and streamlined solution for generating high-quality, accurate legal content with ease.</li>
                                    </ul>
                                </h1>


                                {/* <h1 className={"dark:text-white xl:text-[18px] lg:text-[18px] md:text-[18px] text-[14px] max-h-0 overflow-hidden transition-all duration-500 ease-in-out " + (drop1 ? "max-h-full mt-[14px]" : " ")}></h1> */}

                            </div>
                            <div className="xl:w-2/12 lg:w-2/12 md:w-2/12 w-1/12 relative">
                                {
                                    drop3 ?
                                        <div className="cursor-pointer" onClick={() => { console.log("false"); setDrop3(false) }} >
                                            <ChevronUpIcon className="h-6 w-6 text-white bg-[#218B8F] opacity-100 hover:opacity-80 active:opacity-100 delay-30 duration-300 hover:bg-[#218B8F] active:bg-navy-blue rounded-[8px] absolute top-[24px] right-0"
                                            /> </div> :
                                        <div className="cursor-pointer" onClick={() => { console.log("true"); setDrop3(true) }}>
                                            <ChevronDownIcon className="h-6 w-6 text-white bg-[#218B8F] opacity-100 hover:opacity-80 active:opacity-100 delay-30 duration-300 hover:bg-[#218B8F] active:bg-navy-blue rounded-[8px] absolute top-[24px] right-0"
                                            />
                                        </div>
                                }
                            </div>
                        </div>

                        <div className="border-t-[2px] flex mt-[24px]">
                            <div className="xl:w-10/12 lg:w-10/12 md:w-10/12 w-11/12">
                                <h1 className="dark:text-white xl:text-[20px] lg:text-[20px] md:text-[20px] text-[18px] font-bold mt-[24px]">How can I get early access to BetterLegalContent.Ai?</h1>
                                <h1 className={"dark:text-white xl:text-[18px] lg:text-[18px] md:text-[18px] text-[14px] max-h-0 overflow-hidden transition-all duration-500 ease-in-out " + (drop5 ? "max-h-full mt-[14px]" : " ")}>To get exclusive early access to BetterLegalContent.Ai, simply sign up for updates on our preview landing page. We'll notify you as soon as the platform becomes available for a select group of users.</h1>
                            </div>
                            <div className="xl:w-2/12 lg:w-2/12 md:w-2/12 w-1/12 relative">
                                {
                                    drop5 ?
                                        <div className="cursor-pointer" onClick={() => { console.log("false"); setDrop5(false) }} >
                                            <ChevronUpIcon className="h-6 w-6 text-white bg-[#218B8F] opacity-100 hover:opacity-80 active:opacity-100 delay-30 duration-300 hover:bg-[#218B8F] active:bg-navy-blue rounded-[8px] absolute top-[24px] right-0"
                                            /> </div> :
                                        <div className="cursor-pointer" onClick={() => { console.log("true"); setDrop5(true) }}>
                                            <ChevronDownIcon className="h-6 w-6 text-white bg-[#218B8F] opacity-100 hover:opacity-80 active:opacity-100 delay-30 duration-300 hover:bg-[#218B8F] active:bg-navy-blue rounded-[8px] absolute top-[24px] right-0"
                                            />
                                        </div>
                                }
                            </div>
                        </div>

                        <div className="border-t-[2px] flex mt-[24px]">
                            <div className="xl:w-10/12 lg:w-10/12 md:w-10/12 w-11/12">
                                <h1 className="dark:text-white xl:text-[20px] lg:text-[20px] md:text-[20px] text-[18px] font-bold mt-[24px]">When will BetterLegalContent.Ai officially launch?</h1>
                                <h1 className={"dark:text-white xl:text-[18px] lg:text-[18px] md:text-[18px] text-[14px] max-h-0 overflow-hidden transition-all duration-500 ease-in-out " + (drop7 ? "max-h-full mt-[14px]" : " ")}>We're working hard to bring BetterLegalContent.Ai to you as soon as possible. To stay informed about our progress and receive updates about the official launch, sign up for our mailing list on the preview landing page.</h1>
                            </div>
                            <div className="xl:w-2/12 lg:w-2/12 md:w-2/12 w-1/12 relative">
                                {
                                    drop7 ?
                                        <div className="cursor-pointer" onClick={() => { console.log("false"); setDrop7(false) }} >
                                            <ChevronUpIcon className="h-6 w-6 text-white bg-[#218B8F] opacity-100 hover:opacity-80 active:opacity-100 delay-30 duration-300 hover:bg-[#218B8F] active:bg-navy-blue rounded-[8px] absolute top-[24px] right-0"
                                            /> </div> :
                                        <div className="cursor-pointer" onClick={() => { console.log("true"); setDrop7(true) }}>
                                            <ChevronDownIcon className="h-6 w-6 text-white bg-[#218B8F] opacity-100 hover:opacity-80 active:opacity-100 delay-30 duration-300 hover:bg-[#218B8F] active:bg-navy-blue rounded-[8px] absolute top-[24px] right-0"
                                            />
                                        </div>
                                }
                            </div>
                        </div>
                    </div>

                    <div className="xl:w-6/12 lg:w-6/12 w-full xl:mt-[0] lg:mt-[0] mt-[24px]">
                        <div className="border-t-[2px] flex">
                            <div className="xl:w-10/12 lg:w-10/12 md:w-10/12 w-11/12">
                                <h1 className="dark:text-white xl:text-[20px] lg:text-[20px] md:text-[20px] text-[18px] font-bold mt-[24px]">How does BetterLegalContent.Ai work?</h1>
                                <h1 className={"dark:text-white xl:text-[18px] lg:text-[18px] md:text-[18px] text-[14px] max-h-0 overflow-hidden transition-all duration-500 ease-in-out " + (drop2 ? "max-h-full mt-[14px]" : " ")}> BetterLegalContent.Ai uses advanced AI algorithms to generate relevant, data-driven webpage and blog post suggestions based on your requirements. Our pre-engineered prompts help guide the AI to produce high-quality legal content without the need for extensive content planning. As a member, you'll have unlimited access to AI-generated content and optional upgrades like professional editing and attorney review from a qualified in-house team. </h1>
                            </div>
                            <div className="xl:w-2/12 lg:w-2/12 md:w-2/12 w-1/12 relative">
                                {
                                    drop2 ?
                                        <div className="cursor-pointer" onClick={() => { console.log("false"); setDrop2(false) }} >
                                            <ChevronUpIcon className="h-6 w-6 text-white bg-[#218B8F] opacity-100 hover:opacity-80 active:opacity-100 delay-30 duration-300 hover:bg-[#218B8F] active:bg-navy-blue rounded-[8px] absolute top-[24px] right-0"
                                            /> </div> :
                                        <div className="cursor-pointer" onClick={() => { console.log("true"); setDrop2(true) }}>
                                            <ChevronDownIcon className="h-6 w-6 text-white bg-[#218B8F] opacity-100 hover:opacity-80 active:opacity-100 delay-30 duration-300 hover:bg-[#218B8F] active:bg-navy-blue rounded-[8px] absolute top-[24px] right-0"
                                            />
                                        </div>
                                }
                            </div>
                        </div>

                        <div className="border-t-[2px] flex mt-[24px]">
                            <div className="xl:w-10/12 lg:w-10/12 md:w-10/12 w-11/12">
                                <h1 className="dark:text-white xl:text-[20px] lg:text-[20px] md:text-[20px] text-[18px] font-bold mt-[24px]">How much does BetterLegalContent.Ai cost?</h1>
                                <h1 className={"dark:text-white xl:text-[18px] lg:text-[18px] md:text-[18px] text-[14px] max-h-0 overflow-hidden transition-all duration-500 ease-in-out " + (drop4 ? "max-h-full mt-[14px]" : " ")}>Our pricing is competitive with ChatGPT, providing exceptional value for the range of services we offer. Prices will start at $19.00 for full access to our data center and unlimited content creation. </h1>
                            </div>
                            <div className="xl:w-2/12 lg:w-2/12 md:w-2/12 w-1/12 relative">
                                {
                                    drop4 ?
                                        <div className="cursor-pointer" onClick={() => { console.log("false"); setDrop4(false) }} >
                                            <ChevronUpIcon className="h-6 w-6 text-white bg-[#218B8F] opacity-100 hover:opacity-80 active:opacity-100 delay-30 duration-300 hover:bg-[#218B8F] active:bg-navy-blue rounded-[8px] absolute top-[24px] right-0"
                                            /> </div> :
                                        <div className="cursor-pointer" onClick={() => { console.log("true"); setDrop4(true) }}>
                                            <ChevronDownIcon className="h-6 w-6 text-white bg-[#218B8F] opacity-100 hover:opacity-80 active:opacity-100 delay-30 duration-300 hover:bg-[#218B8F] active:bg-navy-blue rounded-[8px] absolute top-[24px] right-0"
                                            />
                                        </div>
                                }
                            </div>
                        </div>

                        <div className="border-t-[2px] flex mt-[24px]">
                            <div className="xl:w-10/12 lg:w-10/12 md:w-10/12 w-11/12">
                                <h1 className="dark:text-white xl:text-[20px] lg:text-[20px] md:text-[20px] text-[18px] font-bold mt-[24px]">Is the content generated by BetterLegalContent.Ai legally compliant?</h1>
                                <h1 className={"dark:text-white xl:text-[18px] lg:text-[18px] md:text-[18px] text-[14px] max-h-0 overflow-hidden transition-all duration-500 ease-in-out " + (drop6 ? "max-h-full mt-[14px]" : " ")}>While our AI-generated content aims to be as accurate and relevant as possible, we recommend utilizing our upgraded services, which include professional editing and licensed attorney review. These additional services help ensure the highest level of accuracy and legal compliance for your content.</h1>
                            </div>
                            <div className="xl:w-2/12 lg:w-2/12 md:w-2/12 w-1/12 relative">
                                {
                                    drop6 ?
                                        <div className="cursor-pointer" onClick={() => { console.log("false"); setDrop6(false) }} >
                                            <ChevronUpIcon className="h-6 w-6 text-white bg-[#218B8F] opacity-100 hover:opacity-80 active:opacity-100 delay-30 duration-300 hover:bg-[#218B8F] active:bg-navy-blue rounded-[8px] absolute top-[24px] right-0"
                                            /> </div> :
                                        <div className="cursor-pointer" onClick={() => { console.log("true"); setDrop6(true) }}>
                                            <ChevronDownIcon className="h-6 w-6 text-white bg-[#218B8F] opacity-100 hover:opacity-80 active:opacity-100 delay-30 duration-300 hover:bg-[#218B8F] active:bg-navy-blue rounded-[8px] absolute top-[24px] right-0"
                                            />
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Faq;