import { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useThemeContext } from '../../store/ThemeProvider';

const Introduce: FC = ({ }) => {

    const navigate = useNavigate();

    const [{ imageTheme }, { setImageTheme }] = useThemeContext()
    const [email, setEmail] = useState("")
    const [emailValidation, setEmailValidation] = useState('');

    const sendEmail = async () => {
        const path = process.env.REACT_APP_BASE_URL + '/api/getEarlyAccess'
        const request = {
            email: email
        }
        var re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (email === "" || !re.test(email)) {
            setEmailValidation('This field is required.')
            return;     
        } 
        else
            await axios.post(path, request, {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                }
            })
                .then((res) => {
                    console.log(res.data)
                    if (res.data === "Ok") {
                        navigate('/thanks')
                    }
                }).catch((err) => {
                    console.log(err)
                })
    }


    return (
        <div className="relative bg-cover bg-center pt-[80px] pb-[135px] px-[20.5px]" style={imageTheme == "light" ? { backgroundImage: `url(/assets/content/landing/introduce-background-light.png)` } : { backgroundImage: `url(/assets/content/landing/introduce-background-dark.png)` }}>
            <div className='xl:flex lg:flex md:flex block justify-center'>
                <div className='xl:w-[790px] lg:w-[790px] md:w-[790px]'>
                    <h1 className='font-bold xl:text-[40px] lg:text-[40px] md:text-[40px] text-[30px] xl:text-center lg:text-center md:text-center dark:text-white'>Introducing BetterLegalContent.Ai: Revolutionizing Legal Content Creation</h1>
                    <h1 className='mt-[24px] text-[18px] xl:text-center lg:text-center md:text-center font-semibold dark:text-white'>Transform the way you generate legal content with BetterLegalContent.Ai, a cutting-edge legal content platform that combines the power of OpenAI with topics and prompts created by lawyers and experienced legal marketing professionals.</h1>
                    <div className='xl:flex lg:flex md:flex block justify-center mt-[20px]'>
                        <div className='xl:w-[520px] lg:w-[520px] md:w-[520px]'>
                            <div className='xl:flex lg:flex md:flex w-full mt-[10px] gap-[10px]'>
                                <div className='relative xl:w-8/12 lg:w-8/12 md:w-8/12 w-full'>
                                    <input className={'py-[16px] px-[20px] rounded-[8px] w-full flex mx-auto ' + (emailValidation && 'border-red-700 border-[1px]')} value={email} onChange={(e) => { setEmail(e.target.value) }} placeholder='Enter your email here'></input>
                                    <h1 className='absolute -bottom-[20px] left-[20px] text-red-700 text-[12px] font-bold'>{emailValidation}</h1>
                                </div>
                                <button
                                    onClick={sendEmail}
                                    className='xl:mt-[0px] lg:mt-[0px] md:mt-[0px] mt-[20px]  bg-[#218B8F] opacity-100 hover:opacity-80 active:opacity-100 delay-30 duration-300 hover:bg-[#218B8F] active:bg-navy-blue text-white px-[38px] py-[14px] rounded-[8px] my-auto font-bold text-[16px] xl:w-4/12 lg:w-4/12 md:w-4/12 w-full text-center flex justify-center mx-auto'>Get&nbsp;Early&nbsp;Access</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Introduce