import { FC } from 'react'
import { useEffect, useState } from 'react'
import FormControlLabel from '@mui/material/FormControlLabel';
import MaterialUISwitch from './MaterialUISwitch';
import { useThemeContext } from '../../store/ThemeProvider';
import { Link } from 'react-router-dom';

const Header: FC = ({ }) => {

    const [{ imageTheme }, { setImageTheme }] = useThemeContext()

    const [theme, setTheme] = useState('light')

    const toggleTheme = () => {
        theme === 'dark' ? setTheme('light') : setTheme('dark');
        imageTheme === 'dark' ? setImageTheme('light') : setImageTheme('dark');
    };

    useEffect(() => {
        const currentTheme = localStorage.getItem('theme');
        if (currentTheme) {
            setTheme(currentTheme);
        } else {
            const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)')
                .matches;
            prefersDarkMode ? setTheme('dark') : setTheme('light');
        }
    }, [])

    useEffect(() => {
        localStorage.setItem('theme', theme);
        document.documentElement.className = `theme-${theme} ${theme === 'dark' ? 'dark' : ''}`;
    }, [theme]);

    return (
        <>
            <div className='flex justify-between xl:px-[165px] lg:px-[165px] px-[20px] xl:py-[30px] lg:py-[30px]  py-[12px] bg-navy-blue'>
                <div>
                    <Link to="/">
                        <img src='/assets/header/logo.svg' alt='logo'></img>
                    </Link>
                </div>
                <div className='xl:flex lg:flex block justify-center gap-[20px]'>
                    <div className='flex items-center'>
                        <FormControlLabel
                            control={<MaterialUISwitch sx={{ m: 1 }} />}
                            label=""
                            value={theme}
                            onClick={toggleTheme}
                        />
                    </div>
                    <a className='text-white font-bold text-[18px] xl:flex lg:flex hidden items-center '>Unlimited Legal Content</a>
                </div>
            </div>
        </>
    )
}

export default Header;