import { FC } from 'react'
import { BrowserRouter as Router } from 'react-router-dom';
import PublicRoutes from './PubliceRoutes';
import { Footer } from '../component/footer';
import { Header } from '../component/header'

const AppRouter: FC = () => {
    return (
        <>
            <div>
                <Router>
                    <Header></Header>
                    <PublicRoutes />
                    <Footer></Footer>
                </Router>
            </div>
        </>
    )
}

export default AppRouter