import { FC } from 'react'
import { useThemeContext } from '../../store/ThemeProvider';

const Writing : FC = () => {

    const [{imageTheme}, {setImageTheme}] = useThemeContext()
    return (
        <div className='xl:flex lg:flex block xl:px-[155px] lg:px-[155px] md:px-[155px] px-[20px] w-full mt-[130px] gap-[39px]'>
            <div className='w-7/12 xl:block lg:block hidden'>
                <img src={ imageTheme === "light" ? '/assets/content/landing/writing-content-light.png' : '/assets/content/landing/writing-content-dark.png'} alt='writing-content'></img>
            </div>
            <div className='xl:w-5/12 lg:w-5/12 flex items-center'>
                <div>
                    <h1 className='xl:text-[40px] lg:text-[40px] md:text-[40px] text-[30px] font-bold dark:text-white'>AI-Writing But Better</h1>
                    <div className='w-full flex gap-[10px] mt-[20px]'>
                        <div className='xl:w-[35px] xl:h-[35px] lg:w-[35px] lg:h-[35px] md:w-[35px] md:h-[35px] w-[28px] h-[28px] bg-teal flex justify-center rounded-full flex-shrink-0'>
                            <h1 className='flex items-center text-white font-bold xl:text-[20px] lg:text-[20px] md:text-[20px] text-[18px]'>1</h1>
                        </div>
                        <div>
                            <h1 className='font-semibold dark:text-white'><span className='font-bold xl:text-[18px] lg:text-[18px] md:text-[18px] text-[16px] dark:text-white'>Choose A Topic:</span> Choose a topic from our curated list of the most popular, most-read, and highest client-converting web pages and blogs, or enter your own topic.</h1>
                        </div>
                    </div>
                    <div className='w-full mt-[30px] flex gap-[10px]'>
                        <div className='xl:w-[35px] xl:h-[35px] lg:w-[35px] lg:h-[35px] md:w-[35px] md:h-[35px] w-[28px] h-[28px] bg-teal flex justify-center rounded-full flex-shrink-0'>
                            <h1 className='flex items-center text-white font-bold xl:text-[20px] lg:text-[20px] md:text-[20px] text-[18px]'>2</h1>
                        </div>
                        <div>
                            <h1 className='font-semibold dark:text-white'><span className='font-bold text-[18px] dark:text-white'>Add A Few Details:</span> Follow our quick steps to customize your page to your firm, including your firm’s unique value proposition, experience, track record of success, tag lines, and more.</h1>
                        </div>
                    </div>
                    <div className='w-full mt-[30px] flex gap-[10px]'>
                        <div className='xl:w-[35px] xl:h-[35px] lg:w-[35px] lg:h-[35px] md:w-[35px] md:h-[35px] w-[28px] h-[28px] bg-teal flex justify-center rounded-full flex-shrink-0'>
                            <h1 className='flex items-center text-white font-bold xl:text-[20px] lg:text-[20px] md:text-[20px] text-[18px]'>3</h1>
                        </div>
                        <div>
                            <h1 className='font-semibold dark:text-white'><span className='font-bold text-[18px] dark:text-white'>Generate Your Piece:</span> Our platform will instantly create a high-quality page using your firm’s details and our highly-engineered prompts.</h1>
                        </div>
                    </div>
                    <div className='w-full mt-[30px] flex gap-[10px]'>
                        <div className='xl:w-[35px] xl:h-[35px] lg:w-[35px] lg:h-[35px] md:w-[35px] md:h-[35px] w-[28px] h-[28px] bg-teal flex justify-center rounded-full flex-shrink-0'>
                            <h1 className='flex items-center text-white font-bold xl:text-[20px] lg:text-[20px] md:text-[20px] text-[18px]'>4</h1>
                        </div>
                        <div>
                            <h1 className='font-semibold dark:text-white'><span className='font-bold text-[18px] dark:text-white'>Editing and Legal Review:</span> Want Professional Editing or Attorney Review? We have an in-house team of professional editors and editor attorneys with experience in a wide-variety of practice areas ready to assist you.</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className='xl:hidden lg:hidden block mt-[30px]'>
                <img src={ imageTheme === "light" ? '/assets/content/landing/writing-content-light.png' : '/assets/content/landing/writing-content-dark.png'} alt='writing-content'></img>
            </div>
        </div>
    )
}

export default Writing